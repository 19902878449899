<template>
    <div id="cms-breadcrumb">
        <div class="breadcrumb-container">
            <RouterLink :to="'/'">
                <Icon icon="home" class="home-icon"/>
            </RouterLink>
            <h4>
                <span v-for="(rt,index) in router.currentRoute.value.matched" :key="index" :class="`${rt.name === router.currentRoute.value.name ? 'activeBreadcrumb' : ''}`">{{ rt.name }}</span>
            </h4>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import Icon from '@/assets/images/icons/Icon.vue'
const router = useRouter();
</script>

<style lang="scss">
#cms-breadcrumb{
    width: 100%;
    background-color: var(--breadcrumb-background-color);
    border-radius: 8px;

    a{
        display:block;
        text-decoration: none;
    }

    .home-icon{
        cursor: pointer;

        svg{
            width: 18px;
            height: 18px;
        }

        path {
            fill: var(--breadcrumb-text-color);
        }

        &:hover{
            path {
                fill: var(--breadcrumb-hover-color);
            }
        }
    }

    h4{
        font-size: 14px;
        font-weight: 400;
        color: var(--grey-100);
        margin: 0;
    }

    h4 span.activeBreadcrumb{
        font-weight: 700;
        color: var(--black-100);
    }

    h4 span:before{
        content: '';
        width: 12px;
        height: 12px;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512.011 512.011' style='enable-background:new 0 0 512.011 512.011;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath fill=%22%23797979%22 d='M505.755,123.592c-8.341-8.341-21.824-8.341-30.165,0L256.005,343.176L36.421,123.592c-8.341-8.341-21.824-8.341-30.165,0s-8.341,21.824,0,30.165l234.667,234.667c4.16,4.16,9.621,6.251,15.083,6.251c5.462,0,10.923-2.091,15.083-6.251l234.667-234.667C514.096,145.416,514.096,131.933,505.755,123.592z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        transform:rotate(270deg);
        margin: 0 16px;

    }

    .breadcrumb-container{
        padding: 13px 24px;
        display:flex;
        align-items: center;
    }

}
</style>