<template>
    <transition mode="out-in">
        <section class="side-bar" v-if="showSidebar">
            <div class="title">
                <CIcon icon="cilTerminal" />
                <p>Doo Content Management System</p>
            </div>
            <div class="body-sidebar">
                <div class="body-content">
                    <div v-for="item in headerLink" :key="item.title">
                        <div class="item" :class="item.class" v-if="!item.hide">
                            <router-link
                                class="main-link"
                                @click="toggleCollapse(item)"
                                v-if="item?.to && !item.subTitle"
                                :to="item?.to"
                            >
                                <CIcon :icon="item.icon" /> {{ item.title }}
                            </router-link>
                            <div class="subtitle-list" v-else-if="item.subTitle">
                                <div @click="toggleCollapse(item)">
                                    <CIcon :icon="item.icon" />
                                    {{ item.title }}
                                </div>

                                <CCollapse :visible="item.visible">
                                    <div v-for="itemSub in item.subTitle" :key="itemSub.id">
                                        <router-link
                                            @click="navToggle(itemSub.title)"
                                            :class="
                                                itemSub.active
                                                    ? 'router-link-active router-link-exact-active subtitle-link'
                                                    : 'subtitle-link'
                                            "
                                            :to="itemSub?.to"
                                        >
                                            <a
                                                :class="
                                                    itemSub.active
                                                        ? 'router-link-active router-link-exact-active subtitle-text'
                                                        : 'subtitle-text'
                                                "
                                                :href="href"
                                                @click="navigate"
                                                >{{ itemSub.title }}</a
                                            >
                                        </router-link>
                                    </div>
                                </CCollapse>
                            </div>
                            <button v-else @click="item.onClick" class="ml-2">
                                <CIcon :icon="item.icon" /> {{ item.title }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="close-side-bar">
                    <button class="btn-close-bar" @click="() => toggleSidebar(false)">
                        <CIcon icon="cilMenu" />
                    </button>
                </div>
            </div>
            <AppFooter />
        </section>
        <section class="open-side-bar" v-else>
            <div class="title">
                <CIcon icon="cilTerminal" />
            </div>
            <div class="open-side-bar-container">
                <button class="btn-side-bar" @click="() => toggleSidebar(true)">
                    <CIcon icon="cilMenu" />
                </button>
                <div class="icon-mini">
                    <div v-for="item in headerLink" :key="item.title">
                        <div class="item" :class="item.class" v-if="!item.hide">
                            <router-link v-if="item?.to" :to="item.to">
                                <CIcon :icon="item.icon" />
                            </router-link>
                            <button v-else @click="item.onClick">
                                <CIcon :icon="item.icon" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </transition>
</template>

<script setup>
import { useUserStore } from '@/stores/user'
import AppFooter from '@/components/AppFooter.vue'
import { onBeforeMount } from 'vue'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { USER_ROLE } from '@/utils/enum'

const userStore = useUserStore()
const { role } = storeToRefs(userStore)
const showSidebar = ref(true)

let headerLink = ref([
    {
        class: 'dashboard',
        icon: 'cilTerminal',
        title: 'Non-Trade Data Centre',
        to: '/dashboard',
    },
    {
        class: 'PublicComponent',
        icon: 'cilLocationPin',
        title: 'Public Component',
        visible: false,
        subTitle: [
            { id: 1, active: false, title: 'Menu Listing', to: '/publicComponent/menu' },
            { id: 2, active: false, title: 'Social Media', to: '/publicComponent/socialMedia' },
            { id: 3, active: false, title: 'Compliance', to: '/publicComponent/compliance' },
        ],

        hide: role.value !== USER_ROLE.ADMIN,
    },
    {
        class: 'user',
        icon: 'cilUser',
        title: 'User',
        to: '/User',
    },
    {
        class: 'content',
        icon: 'cilUser',
        title: 'Content',
        to: '/content',
        hide: true,
    },
    {
        class: 'Event',
        icon: 'cilOptions',
        title: 'Download Link',
        to: '/dynamicDownloadLink',
        hide: role.value !== USER_ROLE.ADMIN,
    },
    {
        class: 'Event',
        icon: 'cilFile',
        title: 'Event',
        to: '/event',
        hide: role.value !== USER_ROLE.ADMIN,
    },
    {
        class: 'System Logs',
        icon: 'cilSettings',
        title: 'System Logs',
        to: '/systemLogs',
        hide: role.value !== USER_ROLE.ADMIN,
    },
    {
        class: 'Email',
        icon: 'cibGmail',
        title: 'Email Logs',
        to: '/emailLogs',
        hide: role.value !== USER_ROLE.ADMIN,
    },
    {
        class: 'Country',
        icon: 'cilLocationPin',
        title: 'Country',
        visible: false,
        subTitle: [
            { id: 1, active: false, title: 'Country Listing', to: '/country/list' },
            { id: 2, active: false, title: 'Country Tag Configuration', to: '/country/tag' },
        ],

        hide: role.value !== USER_ROLE.ADMIN,
    },
    {
        class: 'logout',
        icon: 'cilAccountLogout',
        title: 'Logout',
        onClick: () => {
            userStore.logout()
        },
    },
])

const getCookie = (cookie) => {
    return document.cookie.split(';').reduce(function (prev, c) {
        var arr = c.split('=')
        return arr[0].trim() === cookie ? arr[1] : prev
    }, undefined)
}
const initSidebar = () => {
    const currentCookie = getCookie('showSidebar')
    const url = window.location.pathname

    if (currentCookie) {
        let convertor = /^true$/i.test(currentCookie)
        toggleSidebar(convertor)
    }
    headerLink.value = headerLink.value.map((o) => {
        let subCheck
        if (
            o.subTitle?.some((u) => {
                subCheck = u

                return url.match(u.to)
            })
        ) {
            return {
                ...o,
                visible: true,
                subTitle: [
                    ...o.subTitle.map((i) => {
                        if (i.id == subCheck.id) {
                            return { ...i, active: true }
                        } else {
                            return { ...i, active: false }
                        }
                    }),
                ],
            }
        } else {
            return { ...o }
        }
    })
}

const toggleCollapse = (item) => {
    let toogleValue = headerLink.value.map((o) => {
        if (o.title == item.title) {
            return { ...o, visible: !o.visible }
        } else if (o.subTitle) {
            let subTitle = o.subTitle.map((u) => {
                return { ...u, active: false }
            })
            return { ...o, visible: false, subTitle: subTitle }
        } else {
            return { ...o, visible: false }
        }
    })

    headerLink.value = toogleValue
}

const navToggle = (item) => {
    headerLink.value = headerLink.value.map((o) => {
        if (o.subTitle?.some((u) => u.title.includes(item))) {
            let subtitle
            subtitle = o.subTitle.map((subItem) => ({
                ...subItem,
                active: subItem.title.includes(item),
            }))
            return { ...o, subTitle: subtitle }
        } else {
            let subtitle
            subtitle = o.subTitle?.map((subItem) => ({
                ...subItem,
                active: false,
            }))

            return { ...o, subTitle: subtitle }
        }
    })

    // headerLink.value =
}

const toggleSidebar = (n) => {
    showSidebar.value = n
}

onBeforeMount(() => {
    initSidebar()
})
</script>

<style scoped lang="scss">
.v-enter-from {
    opacity: 0;
    transform: translateX(-30px);
}

.v-enter-active {
    max-width: 256px !important;
    width: 100%;
    transition: all 1s ease-out;
}

.v-enter-to {
    opacity: 1;
    transform: translateX(0px);
}

.v-leave-from {
    opacity: 1;
    transform: translateX(0px);
}

.v-leave-active {
    max-width: 80px !important;
    width: 100%;
    transition: all 1s ease-in;
}

.v-leave-to {
    opacity: 0;
    transform: translateX(-30px);
}

svg {
    width: 20px;
    height: 20px;
}

.collapse {
    visibility: visible !important;
}

.title {
    margin-bottom: 0;
    padding: 25px 22px 16px;

    svg {
        width: 40px !important;
        height: 40px !important;
        color: #ffffff;
        display: inline-block;
        text-align: center;
        background: #5097fd;
        border-radius: 10px;
        padding: 9px;
        margin-right: 10px;
    }

    p {
        font-size: 16px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.87);
        margin-bottom: 0;
        display: inline-block;
        width: 160px;
    }
}

.side-bar {
    position: sticky;
    top: 0;
    width: 100%;
    max-width: 256px;
    z-index: 10;

    .body-sidebar {
        position: relative;

        p {
            padding: 12px 35px;
            font-size: 16px;
        }

        .body-content {
            padding-top: 30px;
        }
    }

    .item {
        color: #f1f1f1;
        display: flex;
        align-items: center;
        padding: 0 12px 0 7px;

        a,
        .subtitle-list,
        button {
            padding: 12px 17px;
            margin-left: 0;
            color: #85a8cd;
            width: 100%;
            display: block;
            text-decoration: none;
            text-align: left;
            font-weight: lighter;
            font-size: 14px;
            border-radius: 6px;
            margin-left: 0 !important;

            .subtitle-link {
                padding: 5px;
                margin: 10px 0px;
            }
            .subtitle-text {
                color: #85a8cd;
                padding: 5px;
            }

            &:hover {
                color: #fff;
            }

            &.router-link-exact-active.subtitle-link {
                position: relative;
                color: #fff;
                font-weight: 400;
                background: #0c2b50;
                display: flex;
                padding: 5px;

                &:after {
                    content: '';
                    width: 6px;
                    height: 45px;
                    background: #5097fd;
                    position: absolute;
                    right: -12px;
                    top: 50%;
                    margin-top: -22.5px;
                    border-radius: 20px 0 0 20px;
                }
            }

            &.router-link-exact-active.main-link {
                position: relative;
                color: #fff;
                font-weight: 400;
                background: #0c2b50;
                display: flex;
                line-height: 22px;
                padding-left: 45px;

                &:after {
                    content: '';
                    width: 6px;
                    height: 45px;
                    background: #5097fd;
                    position: absolute;
                    right: -12px;
                    top: 50%;
                    margin-top: -22.5px;
                    border-radius: 20px 0 0 20px;
                }

                svg {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    left: 12px;
                    top: 10px;
                    color: #5097fd;
                }
            }

            svg {
                min-width: 16px;
                margin-right: 8px;
            }
        }
    }
}

.open-side-bar {
    width: 80px; // mobile resizes issue
    position: sticky;
    border-radius: 0 30px 30px 0;
    top: 0;
    z-index: 10;
    transition: all 1s ease-out;

    .title {
        padding: 25px 20px 16px;
        margin-bottom: 20px;
    }

    .item {
        padding: 0 16px;
    }

    a,
    .logout button {
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 15px;
        color: #85a8cd;

        &:hover {
            color: #fff;
        }

        &.router-link-exact-active {
            color: #6695fe;
            background: #0c2b50;
            display: block;
            padding: 7px 3px;
            line-height: 22px;
            position: relative;
            text-align: center;
            border-radius: 10px;

            &:after {
                content: '';
                width: 6px;
                height: 38px;
                background: #5097fd;
                position: absolute;
                right: -16px;
                top: 50%;
                margin-top: -19.5px;
                border-radius: 10px 0 0 10px;
            }

            svg {
                width: 25px;
                height: 25px;
            }
        }
    }

    .btn-side-bar {
        background: transparent;
        padding: 6px 10px;
        border: none;
        position: absolute;
        top: 70px;
        right: -18px;
        border-radius: 0 30px 30px 0;
        background: #f2f4fa;
        border-radius: 30px;
        color: var(--theme-text-color);
    }
}

.close-side-bar {
    display: flex;
    justify-content: end;
    min-width: none;
    display: flex;
    align-items: center;
    position: absolute;
    top: -15px;
    right: -18px;

    .btn-close-bar {
        border-radius: 30px;
        padding: 6px 10px;
        background: #f2f4fa;
        border: none;
        color: var(--theme-text-color);
    }
}
</style>
