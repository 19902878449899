<template>
    <div v-html="svg"></div>
</template>
  
  <script setup>
  import { computed } from 'vue';
  import { svgIcons } from '@/assets/images/icons/svgIcons';
  
  const props = defineProps({
    icon: {
      type: String,
      required: true
    }
  });
  
  const svg = computed(() => svgIcons[props.icon] || svgIcons['notfound']);

  </script>

<style>
    svg {
        width: 22px;
        height: 22px;
    }
</style>
  